import About from './About';
import AuthHandler from './AuthHandler';
import Library from './Library';
import Nav from './Nav';
import Prizes from './Prizes';
import React from 'react';
import store from './redux';
import VitalsFinal from './VitalsFinal';
import VitalsInit from './VitalsInit';
import Workouts from './Workouts';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { useContext } from 'react';
import './App.css';
import { ChallengeStageContext } from './context/challengeStageContext';
import { Home } from './Home';
import {
  Security,
  LoginCallback,
  SecureRoute,
} from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { SnackbarProvider } from 'notistack';

const config = {
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
};
const oktaAuth = new OktaAuth(config);

export const App: React.FC = () => {
  const now = new Date();
  const context = useContext(ChallengeStageContext(now));

  return (
    <Provider store={store}>
      <Router>
        <Security oktaAuth={oktaAuth}>
          <div className="App">
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={2000}
              domRoot={document.getElementById('react-notification')!}
            >
              <Nav />
              <AuthHandler />

              <Switch>
                <Route
                  path="/implicit/callback"
                  component={LoginCallback}
                ></Route>
                <SecureRoute path="/about" component={About} />
                <SecureRoute path="/prizes" component={Prizes} />
                {context.shouldDisplayInitialVitals ? (
                  <SecureRoute
                    path="/initial-vitals"
                    component={VitalsInit}
                  />
                ) : null}

                {context.shouldDisplayFinalVitals ? (
                  <SecureRoute
                    path="/final-vitals"
                    component={VitalsFinal}
                  />
                ) : null}

                <SecureRoute path="/library" component={Library} />
                <SecureRoute path="/workouts" component={Workouts} />
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </SnackbarProvider>
          </div>
        </Security>
      </Router>
    </Provider>
  );
};
