import React from 'react';
import './App.css';
import {
  ChallengeStageContext,
  InitChallengeStageContext,
} from './context/challengeStageContext';
import { App } from './App';

export const AppWrapper: React.FC = () => {
  const now = new Date();
  const ChallengeStage = ChallengeStageContext(now);

  return (
    <ChallengeStage.Provider value={InitChallengeStageContext(now)}>
      <App />
    </ChallengeStage.Provider>
  );
};
