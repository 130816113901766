import React, { useContext } from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTrophy,
  faHeartbeat,
  faBookReader,
  faRunning,
  faWeight,
} from '@fortawesome/free-solid-svg-icons';
import logo from './assets/logo-header.png';
import { ChallengeStageContext } from './context/challengeStageContext';

export default () => {
  const now = new Date();
  const context = useContext(ChallengeStageContext(now));

  return (
    <nav>
      <Link to="/">
        <img src={logo} alt="Healthwise" />
      </Link>
      <div className="menu">
        <Link to="/about">
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>About</span>
        </Link>
        <Link to="/prizes">
          <FontAwesomeIcon icon={faTrophy} />
          <span>Prizes</span>
        </Link>
        {context.shouldDisplayInitialVitals ? (
          <Link to="/initial-vitals">
            <FontAwesomeIcon icon={faWeight} />
            <span>Initial Vitals</span>
          </Link>
        ) : null}

        {context.shouldDisplayFinalVitals ? (
          <Link to="/final-vitals">
            <FontAwesomeIcon icon={faHeartbeat} />
            <span>Final Vitals</span>
          </Link>
        ) : null}

        <Link to="/library">
          <FontAwesomeIcon icon={faBookReader} />
          <span>Library</span>
        </Link>
        <Link to="/workouts">
          <FontAwesomeIcon icon={faRunning} />
          <span>Workouts</span>
        </Link>
      </div>
    </nav>
  );
};
