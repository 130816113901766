import React from 'react';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';

interface Props {
  loading: boolean;
  user: any;
}

const User = ({ loading, user }: Props) => {
  const { authState } = useOktaAuth();

  return !authState.isAuthenticated ? (
    <Link to="/login" className="App-link">
      Sign In
    </Link>
  ) : (
    <div>
      Hi, <span className="user-name">{user?.given_name}</span>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(User);
