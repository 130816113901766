import { createSlice } from 'redux-starter-kit';

const { actions, reducer } = createSlice({
  name: 'vitals',
  initialState: {
    loading: true,
    dirty: false,
    saving: false,
    savingResult: '',
    final: {
      arm_left: '',
      arm_right: '',
      chest: '',
      waist: '',
      abdominals: '',
      hips: '',
      thigh_left: '',
      thigh_right: '',
      heartrate: '',
      pushups: '',
      plank_min: '',
      plank_sec: '',
      wallsit_min: '',
      wallsit_sec: '',
      testimonial: '',
    },
    initial: {
      arm_left: '',
      arm_right: '',
      chest: '',
      waist: '',
      abdominals: '',
      hips: '',
      thigh_left: '',
      thigh_right: '',
      heartrate: '',
      pushups: '',
      plank_min: '',
      plank_sec: '',
      wallsit_min: '',
      wallsit_sec: '',
      goal1: '',
      goal2: '',
      goal3: '',
      goal4: '',
      leader: '',
    },
  },
  reducers: {
    getVitalsStart(state) {
      state.loading = true;
    },
    getInitVitalsSuccess(state, { payload }) {
      state.loading = false;
      state.final = {
        ...state.final,
      };
      state.initial = {
        ...state.initial,
        ...payload,
      };
    },
    getFinalVitalsSuccess(state, { payload }) {
      state.loading = false;
      state.final = {
        ...state.final,
        ...payload,
      };
      state.initial = {
        ...state.initial,
      };
    },
    getVitalsFailure(state) {
      state.loading = false;
    },
    setInitVitals(state, { payload }) {
      state.dirty = true;
      state.final = {
        ...state.final,
      };
      state.initial = {
        ...state.initial,
        ...payload,
      };
    },
    setFinalVitals(state, { payload }) {
      state.dirty = true;

      state.final = {
        ...state.final,
        ...payload,
      };
      state.initial = {
        ...state.initial,
      };
    },
    saveVitalsStart(state) {
      state.saving = true;
      state.savingResult = '';
    },
    clearSaveVitalsResult(state) {
      state.savingResult = '';
    },
    saveVitalsSuccess(state) {
      state.saving = false;
      state.dirty = false;
      state.savingResult = 'success';
    },
    saveVitalsFailure(state) {
      state.saving = false;
      state.savingResult = 'failed';
    },
  },
});

export const {
  getVitalsStart,
  getInitVitalsSuccess,
  getFinalVitalsSuccess,
  getVitalsFailure,
  setInitVitals,
  setFinalVitals,
  saveVitalsStart,
  saveVitalsSuccess,
  saveVitalsFailure,
  clearSaveVitalsResult,
} = actions;

export default reducer;

export const getVitals = (token: string, isInit: boolean) => async (
  dispatch: any,
) => {
  dispatch(getVitalsStart());
  try {
    const path = isInit ? '/api/vitals/init' : '/api/vitals/final';
    const res = await fetch(path, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-store,no-cache',
      },
    });
    const json = await res.json();
    const action = isInit
      ? getInitVitalsSuccess(json)
      : getFinalVitalsSuccess(json);
    dispatch(action);
  } catch {
    dispatch(getVitalsFailure());
  }
};

export const saveVitals = (token: string, isInit: boolean) => async (
  dispatch: any,
  state: any,
) => {
  dispatch(saveVitalsStart());
  try {
    const path = isInit ? '/api/vitals/init' : '/api/vitals/final';
    const res = await fetch(path, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store,no-cache',
      },

      body: JSON.stringify(
        isInit ? state().vitals.initial : state().vitals.final,
      ),
    });
    if (res.ok) {
      dispatch(saveVitalsSuccess());
    } else {
      dispatch(saveVitalsFailure());
    }
  } catch {
    dispatch(saveVitalsFailure());
  }
};

export const clearSaveVitalsResults = () => (dispatch: any) => {
  dispatch(clearSaveVitalsResult());
};
