import { configureStore } from 'redux-starter-kit';
import auth from './auth';
import vitals from './vitals';

export default configureStore({
  reducer: {
    auth,
    vitals,
  },
});
