import React, {
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import './Vitals.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faPortrait,
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import {
  getVitals,
  setInitVitals,
  saveVitals,
  clearSaveVitalsResults,
} from './redux/vitals';
import { ChallengeStageContext } from './context/challengeStageContext';
import imageCompression from 'browser-image-compression';

// @ts-ignore
const VitalsInit = (x) => {
  const now = new Date();
  const context = useContext(ChallengeStageContext(now));

  const [ready, setReady] = useState(context.canEnterInitialVitals);
  const { enqueueSnackbar } = useSnackbar();
  const frontRef = useRef();
  const sideRef = useRef();
  const backRef = useRef();

  useEffect(() => {
    x.token && x.getVitals(x.token, true);
  }, [x.token, x.getVitals]);

  useEffect(() => {
    setReady(context.canEnterInitialVitals);
    if (x.savingResult !== '') {
      if (x.savingResult === 'success') {
        enqueueSnackbar('Your Initial Vitals have been updated.', {
          variant: 'success',
        });
      }

      if (x.savingResult === 'failed') {
        enqueueSnackbar(
          'Failed to update Initial Vitals, please try again later.',
          {
            variant: 'error',
          },
        );
      }
      x.clearSaveVitalsResults();
    }
  }, [x.savingResult]);

  // @ts-ignore
  const beforeUnload = (e) => {
    if (x.dirty) {
      e.preventDefault();
      e.returnValue = '';
      return e.returnValue;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () =>
      window.removeEventListener('beforeunload', beforeUnload);
  });

  // @ts-ignore
  const onChange = (event) => {
    const { name, value } = event.target;
    x.setInitVitals({
      [name]: value,
    });
  };

  // @ts-ignore
  const encodePhoto = async (event) => {
    const { name, files } = event.target;
    if (files[0]) {
      console.log(
        `originalFile size ${files[0].size / 1024 / 1024} MB`,
      );

      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1280,
        useWebWorker: false,
        fileType: 'image/jpeg',
      };

      try {
        const compressedFile = await imageCompression(
          files[0],
          options,
        );

        console.log(
          `compressedFile size ${
            compressedFile.size / 1024 / 1024
          } MB`,
        );
        var reader = new FileReader();
        reader.onloadend = function () {
          x.setInitVitals({
            [name]: reader.result,
          });
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        var reader = new FileReader();
        reader.onloadend = function () {
          x.setInitVitals({
            [name]: reader.result,
          });
        };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  // @ts-ignore
  const onSubmit = (event) => {
    setReady(false);
    x.saveVitals(x.token, true);
    event.preventDefault();
  };

  if (x.loading) {
    return (
      <>
        <h2 className="vitals-title">Initial Vitals</h2>
        <div>
          <label>
            <FontAwesomeIcon icon={faSpinner} size="6x" spin />
          </label>
        </div>
      </>
    );
  }
  // @ts-ignore
  return (
    <>
      <h2 className="vitals-title">Initial Vitals</h2>

      <form onSubmit={onSubmit} className="vitals-form">
        <fieldset className="vitals-fieldset fieldset-photos">
          <legend>Upload Photo</legend>
          <div
            className={
              x.vitals.side || x.vitals.front
                ? 'form-field'
                : 'form-field no-photo'
            }
          >
            <div className="form-field-photo-div">
              <div
                onClick={() => {
                  // @ts-ignore
                  frontRef.current.click();
                }}
              >
                {x.vitals.front ? (
                  <img src={x.vitals.front} />
                ) : (
                  <FontAwesomeIcon
                    icon={faPortrait}
                    size="8x"
                    className={ready ? '' : 'icon-disabled'}
                  />
                )}

                <input
                  disabled={!ready}
                  type="file"
                  id="front"
                  name="front"
                  accept="image/*"
                  onChange={encodePhoto}
                  // @ts-ignore
                  ref={frontRef}
                />
              </div>
              <label>Front</label>
            </div>
            <div className="form-field-photo-div">
              <div
                onClick={() => {
                  // @ts-ignore
                  sideRef.current.click();
                }}
              >
                {x.vitals.side ? (
                  <img src={x.vitals.side} />
                ) : (
                  <FontAwesomeIcon
                    icon={faPortrait}
                    size="8x"
                    className={ready ? '' : 'icon-disabled'}
                  />
                )}

                <input
                  disabled={!ready}
                  // @ts-ignore
                  ref={sideRef}
                  type="file"
                  id="side"
                  name="side"
                  accept="image/*"
                  onChange={encodePhoto}
                />
              </div>
              <label>Side</label>
            </div>
            <div className="form-field-photo-div">
              <div
                onClick={() => {
                  // @ts-ignore
                  backRef.current.click();
                }}
              >
                {x.vitals.back ? (
                  <img src={x.vitals.back} />
                ) : (
                  <FontAwesomeIcon
                    icon={faPortrait}
                    size="8x"
                    className={ready ? '' : 'icon-disabled'}
                  />
                )}

                <input
                  disabled={!ready}
                  // @ts-ignore
                  ref={backRef}
                  type="file"
                  id="back"
                  name="back"
                  accept="image/*"
                  onChange={encodePhoto}
                />
              </div>
              <label>Back</label>
            </div>
          </div>
        </fieldset>

        <fieldset className="vitals-fieldset fieldset-measurements">
          <legend>Measurements</legend>
          <div className="form-field">
            <label>Arm (L/R)</label>
            <div className="form-field-multi-input-container">
              <div>
                <input
                  disabled={!ready}
                  type="text"
                  inputMode="decimal"
                  name="arm_left"
                  value={x.vitals.arm_left}
                  onChange={onChange}
                />
                <span className="unit">cm</span>
              </div>
              <div>
                <input
                  disabled={!ready}
                  type="text"
                  inputMode="decimal"
                  name="arm_right"
                  value={x.vitals.arm_right}
                  onChange={onChange}
                />
                <span className="unit">cm</span>
              </div>
            </div>
          </div>
          <div className="form-field">
            <label>Chest</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="chest"
                value={x.vitals.chest}
                onChange={onChange}
              />
              <span className="unit">cm</span>
            </div>
          </div>
          <div className="form-field">
            <label>Waist</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="waist"
                value={x.vitals.waist}
                onChange={onChange}
              />
              <span className="unit">cm</span>
            </div>
          </div>
          <div className="form-field">
            <label>Abdominals</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="abdominals"
                value={x.vitals.abdominals}
                onChange={onChange}
              />
              <span className="unit">cm</span>
            </div>
          </div>
          <div className="form-field">
            <label>Hips (Bum)</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="hips"
                value={x.vitals.hips}
                onChange={onChange}
              />
              <span className="unit">cm</span>
            </div>
          </div>
          <div className="form-field">
            <label>Upper Thigh (L/R)</label>
            <div className="form-field-multi-input-container">
              <div>
                <input
                  disabled={!ready}
                  type="text"
                  inputMode="decimal"
                  name="thigh_left"
                  value={x.vitals.thigh_left}
                  onChange={onChange}
                />
                <span className="unit">cm</span>
              </div>
              <div>
                <input
                  disabled={!ready}
                  type="text"
                  inputMode="decimal"
                  name="thigh_right"
                  value={x.vitals.thigh_right}
                  onChange={onChange}
                />
                <span className="unit">cm</span>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className="vitals-fieldset fieldset-fitness">
          <legend>Fitness</legend>
          <div className="form-field">
            <label>Resting Heart Rate</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="heartrate"
                value={x.vitals.heartrate}
                onChange={onChange}
              />
              <span className="unit">bpm</span>
            </div>
          </div>
          <div className="form-field">
            <label>Pushups (max. in 1 minute)</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="pushups"
                value={x.vitals.pushups}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-field">
            <label> Plank Hold</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="plank_min"
                value={x.vitals.plank_min}
                onChange={onChange}
              />
              <span className="divider">:</span>
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="plank_sec"
                value={x.vitals.plank_sec}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-field">
            <label>Wall Sit</label>
            <div className="form-field-input-container">
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="wallsit_min"
                value={x.vitals.wallsit_min}
                onChange={onChange}
              />
              <span className="divider">:</span>
              <input
                disabled={!ready}
                type="text"
                inputMode="decimal"
                name="wallsit_sec"
                value={x.vitals.wallsit_sec}
                onChange={onChange}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="vitals-fieldset fieldset-smart-goals">
          <legend>SMART Goals</legend>
          <div className="form-field">
            <label>Goal 1</label>
            <div className="form-field-input-container">
              <textarea
                disabled={!ready}
                name="goal1"
                rows={7}
                value={x.vitals.goal1}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-field">
            <label>Goal 2</label>
            <div className="form-field-input-container">
              <textarea
                disabled={!ready}
                rows={7}
                name="goal2"
                value={x.vitals.goal2}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-field">
            <label>Goal 3</label>
            <div className="form-field-input-container">
              <textarea
                disabled={!ready}
                name="goal3"
                rows={7}
                value={x.vitals.goal3}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-field">
            <label>Goal 4</label>
            <div className="form-field-input-container">
              <textarea
                disabled={!ready}
                name="goal4"
                rows={7}
                value={x.vitals.goal4}
                onChange={onChange}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="vitals-fieldset fieldset-location">
          <legend>Your Location</legend>
          <div className="form-field">
            <div className="form-field-input-container">
              <select
                disabled={!ready}
                name="leader"
                value={x.vitals.leader}
                onChange={onChange}
              >
                <option value="" disabled>
                  Please select...
                </option>
                <option value="QLD">AU - QLD</option>
                <option value="NSW/ACT">AU - NSW / ACT</option>
                <option value="Vic/Tas">AU - Vic / Tas</option>
                <option value="WA/SA/NT">AU - WA / SA / NT</option>
                <option value="Canada">Canada</option>
                <option value="India">India</option>
                <option value="Mexico/LATAM">
                  Mexico / Latin America
                </option>
                <option value="NZ">New Zealand</option>
                <option value="ZA">South Africa</option>
                <option value="UAE">United Arab Emirates</option>
                <option value="Europe">UK / Europe</option>
                <option value="USA">USA</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
        </fieldset>

        {ready ? (
          <input
            disabled={!ready}
            type="submit"
            value={x.saving ? 'Saving...' : 'Save'}
          />
        ) : null}
      </form>
    </>
  );
};

const mapStateToProps = (state: {
  vitals: {
    loading: any;
    dirty: any;
    initial: any;
    saving: any;
    savingResult: any;
  };
  auth: { token: any };
}) => ({
  loading: state.vitals.loading,
  dirty: state.vitals.dirty,
  vitals: state.vitals.initial,
  token: state.auth.token,
  saving: state.vitals.saving,
  savingResult: state.vitals.savingResult,
});

const mapDispatchToProps = {
  getVitals,
  setInitVitals,
  saveVitals,
  clearSaveVitalsResults,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VitalsInit);
