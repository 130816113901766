import React from 'react';
import { ChallengeStage } from '../models/challengeStage';

export type ChallengeStageContextType = {
  currentStage: ChallengeStage;
  shouldDisplayInitialVitals: boolean;
  isInitialVitalsStage: boolean;
  canEnterInitialVitals: boolean;
  shouldDisplayFinalVitals: boolean;
  isFinalVitalsStage: boolean;
  canEnterFinalVitals: boolean;
};

const currentStage = (now: Date): ChallengeStage => {
  const isBeforeUtc = (
    year: number,
    month: number, // Indexed from 1
    day: number,
  ): boolean => {
    const comparisonTime = new Date(
      Date.UTC(year, month - 1, day),
    ).getTime();

    return now.getTime() < comparisonTime;
  };

  if (isBeforeUtc(2024, 1, 15)) {
    return ChallengeStage.NotStarted;
  } else if (isBeforeUtc(2024, 1, 29)) {
    return ChallengeStage.InitVitalsEntryStart;
  } else if (isBeforeUtc(2024, 3, 10)) {
    return ChallengeStage.InitVitalsEntryFinished;
  } else if (isBeforeUtc(2024, 3, 24)) {
    return ChallengeStage.FinalVitalsEntryStart;
  } else if (isBeforeUtc(2024, 4, 1)) {
    return ChallengeStage.FinalVitalsEntryFinished;
  } else return ChallengeStage.Finished;
};

export const InitChallengeStageContext = (
  now: Date,
): ChallengeStageContextType => {
  const current = currentStage(now);

  return {
    currentStage: current,
    isInitialVitalsStage:
      current === ChallengeStage.InitVitalsEntryStart ||
      current === ChallengeStage.InitVitalsEntryFinished,

    shouldDisplayInitialVitals: current !== ChallengeStage.NotStarted,

    canEnterInitialVitals:
      current === ChallengeStage.InitVitalsEntryStart,

    isFinalVitalsStage:
      current === ChallengeStage.FinalVitalsEntryFinished ||
      current === ChallengeStage.FinalVitalsEntryStart,

    shouldDisplayFinalVitals:
      current === ChallengeStage.FinalVitalsEntryFinished ||
      current === ChallengeStage.FinalVitalsEntryStart ||
      current === ChallengeStage.Finished,

    canEnterFinalVitals:
      current === ChallengeStage.FinalVitalsEntryStart,
  };
};

export const ChallengeStageContext = (now: Date) =>
  React.createContext<ChallengeStageContextType>(
    InitChallengeStageContext(now),
  );
