import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setAuth } from './redux/auth';

const AuthHandler = ({ location, token, setAuth }) => {
  const { oktaAuth, authState } = useOktaAuth();

  React.useEffect(() => {
    const nextToken = oktaAuth.getAccessToken();

    if (nextToken !== token) {
      oktaAuth
        .getUser()
        .then((user) => {
          setAuth({ token: nextToken, user });
        })
        .catch((e) => {
          oktaAuth.tokenManager.clear();
          window.location.reload();
        })
        .catch((e) => {});
    }
  }, [authState, oktaAuth]);

  React.useEffect(() => {
    if (location.pathname === '/login') {
      oktaAuth.signInWithRedirect('/').catch((e) => {
        oktaAuth.tokenManager.clear();
      });
    }

    if (location.pathname === '/logout') {
      oktaAuth.tokenManager.clear();
      oktaAuth.signOut('/');
    }
  }, [oktaAuth, location.pathname]);

  return null;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = { setAuth };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthHandler);
