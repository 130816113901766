import React from 'react';

export default () => {
  return (
    <div className="content workouts">
      <div className="content-workouts-section">
        <h2>Week 1</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/y36RTxAN1sk"
                allowFullScreen
                key="y36RTxAN1sk"
                title="w1-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/AiUrWeTODBk"
                allowFullScreen
                key="AiUrWeTODBk"
                title="w1-hiit-timed-1"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/D362S7C6qog"
                allowFullScreen
                key="D362S7C6qog"
                title="w1-hiit-timed-2"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/HE-dEToJA34"
                allowFullScreen
                key="HE-dEToJA34"
                title="w1-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/Px8CpHwrIcg"
                allowFullScreen
                key="Px8CpHwrIcg"
                title="w1-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 2</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/FEk1INtVI04"
                allowFullScreen
                key="FEk1INtVI04"
                title="w2-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/SWEttqH43l8"
                allowFullScreen
                key="SWEttqH43l8"
                title="w2-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/mE1PadCxXcM"
                allowFullScreen
                key="mE1PadCxXcM"
                title="w2-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/d8Q3uQ2XEqg"
                allowFullScreen
                key="d8Q3uQ2XEqg"
                title="w2-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 3</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/jny8z1G96Bk"
                allowFullScreen
                key="jny8z1G96Bk"
                title="w3-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/U7t7flDv1dk"
                allowFullScreen
                key="U7t7flDv1dk"
                title="w3-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/9MzguFtT8dI"
                allowFullScreen
                key="9MzguFtT8dI"
                title="w3-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/izpgG0NNStE"
                allowFullScreen
                key="izpgG0NNStE"
                title="w3-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 4</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/yBl9ZqK5vFo"
                allowFullScreen
                key="yBl9ZqK5vFo"
                title="w4-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/wEP_2IWbM4Q"
                allowFullScreen
                key="wEP_2IWbM4Q"
                title="w4-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/2pzRa7HZozA"
                allowFullScreen
                key="2pzRa7HZozA"
                title="w4-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/h1OXwdjLsJ8"
                allowFullScreen
                key="h1OXwdjLsJ8"
                title="w4-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 5</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/TuCCgej8UxI"
                allowFullScreen
                key="TuCCgej8UxI"
                title="w5-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/VfsLBdRmBIo"
                allowFullScreen
                key="VfsLBdRmBIo"
                title="w5-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/XGvCkwDEcQA"
                allowFullScreen
                key="XGvCkwDEcQA"
                title="w5-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/2Iy5B-JccIM"
                allowFullScreen
                key="2Iy5B-JccIM"
                title="w5-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 6</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/-s6X1aMGm7Q"
                allowFullScreen
                key="-s6X1aMGm7Q"
                title="w6-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/v0B7u_p4NeU"
                allowFullScreen
                key="v0B7u_p4NeU"
                title="w6-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/-6DBpK0Q6z0"
                allowFullScreen
                key="-6DBpK0Q6z0"
                title="w6-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/iVVNnHnQyJw"
                allowFullScreen
                key="iVVNnHnQyJw"
                title="w6-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 7</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/eYmivqP58sM"
                allowFullScreen
                key="eYmivqP58sM"
                title="w7-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/vmuPl3pruhQ"
                allowFullScreen
                key="vmuPl3pruhQ"
                title="w7-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/vJUfJ1rX4YI"
                allowFullScreen
                key="vJUfJ1rX4YI"
                title="w7-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/vRJesvb-LWQ"
                allowFullScreen
                key="vRJesvb-LWQ"
                title="w7-yoga"
              />
            </div>
          </li>
        </ul>
      </div>

      <div className="content-workouts-section">
        <h2>Week 8</h2>
        <ul>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/eYmivqP58sM"
                allowFullScreen
                key="eYmivqP58sM"
                title="w8-mindset-meditation"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/HJdzlTYWlUA"
                allowFullScreen
                key="HJdzlTYWlUA"
                title="w8-hiit"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/0qZCbQXeBMo"
                allowFullScreen
                key="0qZCbQXeBMo"
                title="w8-pilates"
              />
            </div>
          </li>
          <li>
            <div className="workouts-video">
              <iframe
                src="https://www.youtube.com/embed/ge1e_Byhyko"
                allowFullScreen
                key="ge1e_Byhyko"
                title="w8-yoga"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
